














































































































































import Vue from 'vue'
import Loader from '@/vue/components/Loader.vue'
import { ErrorHandler } from '@/ts/helpers/error-handler'
import { callers } from '@/api'
import ErrorMessage from '@/vue/components/ErrorMessage.vue'
import config from '@/config'
import CopyButton from '@/vue/components/CopyButton.vue'

export default Vue.extend({
  name: 'pair-page',
  components: {
    Loader,
    ErrorMessage,
    CopyButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isLoaded: false,
      isLoadFailed: false,
      pair: {},
      config,
    }
  },
  created () {
    this.loadPair()
  },
  methods: {
    async loadPair () {
      try {
        const { data } = await callers.getPair(this.id)
        this.pair = data
      } catch (error) {
        this.isLoadFailed = true
        ErrorHandler.processWithoutFeedback(error as Error)
      }
      this.isLoaded = true
    },
  },
})
