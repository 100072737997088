












import Vue from 'vue'
import { ICON_NAMES } from '@/ts/const/icon-names.const'

export default Vue.extend({
  name: 'no-data-message',
  props: {
    iconName: { type: String, default: ICON_NAMES.empty },
    message: { type: String, required: true },
  },
})
