































import Vue from 'vue'
import Loader from '@/vue/components/Loader.vue'
import CollectionLoader from '@/vue/components/CollectionLoader.vue'
import { ErrorHandler } from '@/ts/helpers/error-handler'
import { callers } from '@/api'
import ErrorMessage from '@/vue/components/ErrorMessage.vue'
import NoDataMessage from '@/vue/components/NoDataMessage.vue'
import PairsTable from '@/vue/components/PairsTable.vue'

export default Vue.extend({
  name: 'pair-list',
  components: {
    CollectionLoader,
    Loader,
    ErrorMessage,
    NoDataMessage,
    PairsTable,
  },
  props: {
    tokenId: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isLoaded: false,
      isLoadFailed: false,
      list: [],
    }
  },
  methods: {
    async getList () {
      let result
      this.isLoaded = false
      this.isLoadFailed = false

      try {
        result = await callers.getPairs({
          ...(this.tokenId && { 'filter[token_id]': this.tokenId }),
        })
      } catch (error) {
        this.isLoadFailed = true
        ErrorHandler.processWithoutFeedback(error as Error)
      }
      this.isLoaded = true

      return result
    },

    setList (newList: []) {
      this.list = newList
    },

    concatList (newChunk: []) {
      this.list = this.list.concat(newChunk)
    },
  },
})
