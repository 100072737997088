
































import Vue from 'vue'
import TokenList from '@/vue/components/TokenList.vue'
import PairList from '@/vue/components/PairList.vue'
import HomePageStatistic from '@/vue/pages/HomePage/HomePageStatistic.vue'

export default Vue.extend({
  name: 'home-page',
  components: { TokenList, PairList, HomePageStatistic },
})
