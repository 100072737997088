










import Vue from 'vue'
import TokenList from '@/vue/components/TokenList.vue'

export default Vue.extend({
  name: 'tokens-page',
  components: { TokenList },
})
