






















































import Vue, { PropType } from 'vue'
import { Token } from '@/typings/token'

export default Vue.extend({
  name: 'tokens-table',
  props: {
    tokens: {
      type: Array as PropType<Token[]>,
      required: true,
    },
  },

})
