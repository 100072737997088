
































import Vue from 'vue'
import { lockBodyScroll, unlockBodyScroll } from '@/ts/helpers/scroll-lock'

export default Vue.extend({
  name: 'app-navigation-mobile',
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    isShown (val) {
      if (val) {
        lockBodyScroll()
      } else {
        unlockBodyScroll()
      }
    },
  },
  methods: {
    closeNavigation () {
      this.$emit('update:isShown', false)
    },
  },
})
