

















import Vue from 'vue'

import { ICON_NAMES } from '@/ts/const/icon-names.const'
import EyeIcon from '@/vue/components/icons/EyeIcon.vue'
import AlertIcon from '@/vue/components/icons/AlertIcon.vue'
import CloseIcon from '@/vue/components/icons/CloseIcon.vue'
import ClockIcon from '@/vue/components/icons/ClockIcon.vue'
import InfoIcon from '@/vue/components/icons/InfoIcon.vue'
import CheckboxCircleIcon from '@/vue/components/icons/CheckboxCircleIcon.vue'
import CopyIcon from '@/vue/components/icons/CopyIcon.vue'
import BurgerBtnIcon from '@/vue/components/icons/BurgerBtnIcon.vue'
import ChevronDownIcon from '@/vue/components/icons/ChevronDownIcon.vue'
import HandCoinIcon from '@/vue/components/icons/HandCoinIcon.vue'
import ErrorIcon from '@/vue/components/icons/ErrorIcon.vue'
import EmptyIcon from '@/vue/components/icons/EmptyIcon.vue'

const validateIconName = (name: string) =>
  Object.values(ICON_NAMES).includes(name)

export default Vue.extend({
  name: 'icon',
  components: {
    EyeIcon,
    AlertIcon,
    CloseIcon,
    ClockIcon,
    InfoIcon,
    CheckboxCircleIcon,
    CopyIcon,
    BurgerBtnIcon,
    ChevronDownIcon,
    HandCoinIcon,
    ErrorIcon,
    EmptyIcon,
  },
  props: {
    name: { type: String, required: true, validator: validateIconName },
  },
  data () {
    return {
      ICON_NAMES,
    }
  },
})
