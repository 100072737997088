
















































import Vue from 'vue'
import AppNavigationMobile from '@/vue/components/AppNavigationMobile.vue'
import { ICON_NAMES } from '@/ts/const/icon-names.const'

export default Vue.extend({
  name: 'app-header',
  components: { AppNavigationMobile },
  data () {
    return {
      isMobileNavigationShown: false,
      ICON_NAMES,
    }
  },
})
