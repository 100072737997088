












import Vue from 'vue'

export default Vue.extend({
  name: 'loader',
  props: {
    messageId: { type: String, default: '' },
    positionCenter: { type: Boolean, default: true },
  },
})
