











import Vue from 'vue'
import config from '@/config'
import AppHeader from '@/vue/components/AppHeader.vue'

export default Vue.extend({
  name: 'app',
  components: { AppHeader },
  data () {
    return {
      isAppInitialized: false,
    }
  },

  async created () {
    this.setAppTitle()
    this.isAppInitialized = true
  },

  methods: {
    setAppTitle () : void {
      document.title = config.APP_NAME
    },
  },
})
