






















































import Vue, { PropType } from 'vue'
import { Pair } from '@/typings/pair'

export default Vue.extend({
  name: 'pairs-table',
  props: {
    pairs: {
      type: Array as PropType<Pair[]>,
      required: true,
    },
  },

})
