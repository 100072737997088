










import Vue from 'vue'
import PairList from '@/vue/components/PairList.vue'

export default Vue.extend({
  name: 'pairs-page',
  components: { PairList },
})
