















import Vue from 'vue'
import { ICON_NAMES } from '@/ts/const/icon-names.const'

const SCHEME = {
  row: 'row',
  default: '',
}

export default Vue.extend({
  name: 'error-message',
  props: {
    iconName: { type: String, default: ICON_NAMES.error },
    message: { type: String, required: true },
    scheme: { type: String, default: SCHEME.default },
  },
})
