



































































































































import Vue from 'vue'
import Loader from '@/vue/components/Loader.vue'
import { ErrorHandler } from '@/ts/helpers/error-handler'
import { callers } from '@/api'
import ErrorMessage from '@/vue/components/ErrorMessage.vue'
import PairList from '@/vue/components/PairList.vue'
import config from '@/config'
import CopyButton from '@/vue/components/CopyButton.vue'

export default Vue.extend({
  name: 'token-page',
  components: {
    Loader,
    ErrorMessage,
    PairList,
    CopyButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isLoaded: false,
      isLoadFailed: false,
      token: {},
      config,
    }
  },
  created () {
    this.loadToken()
  },
  methods: {
    async loadToken () {
      try {
        const { data } = await callers.getToken(this.id)
        this.token = data
      } catch (error) {
        this.isLoadFailed = true
        ErrorHandler.processWithoutFeedback(error as Error)
      }
      this.isLoaded = true
    },
  },
})
