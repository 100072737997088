import { render, staticRenderFns } from "./CopyButton.vue?vue&type=template&id=10cb5242&scoped=true&"
import script from "./CopyButton.vue?vue&type=script&lang=js&"
export * from "./CopyButton.vue?vue&type=script&lang=js&"
import style0 from "./CopyButton.vue?vue&type=style&index=0&id=10cb5242&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10cb5242",
  null
  
)

export default component.exports