













































import Vue from 'vue'
import Loader from '@/vue/components/Loader.vue'
import { ErrorHandler } from '@/ts/helpers/error-handler'
import { callers } from '@/api'
import ErrorMessage from '@/vue/components/ErrorMessage.vue'

export default Vue.extend({
  name: 'home-page-statistic',
  components: {
    Loader,
    ErrorMessage,
  },
  data () {
    return {
      isLoaded: false,
      isLoadFailed: false,
      statistics: {},
    }
  },
  created () {
    this.loadStatistics()
  },
  methods: {
    async loadStatistics () {
      try {
        const { data } = await callers.getStatistics()
        this.statistics = data
      } catch (error) {
        this.isLoadFailed = true
        ErrorHandler.processWithoutFeedback(error as Error)
      }
      this.isLoaded = true
    },
  },
})
